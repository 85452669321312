label {
    margin-top: 0rem;
    margin-bottom: 0rem;
}

.searchUrl {
    color: blue;
    font-size: 0.875em;
    overflow-wrap: break-word;
}

.searchHeader {
    font-size: 1.25rem;
}

.fullpage {
    min-height: 50vh;
}

.leaflet-container {
    width: 100%;
    height: 500px;
}

.container{
    max-width: 1400px;
 }

 .alert-annc {
    background-color: #e5d0ff;
    color: black;
 }